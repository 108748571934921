<template>
    <div class="h-100" style="min-height: 82vh">
        <div v-if="isLoading">
            <center style="margin-top: 25%">
                <span
                    class="spinner-border text-dark spinner-border-lg ml-1"
                ></span>
            </center>
        </div>
        <div v-else class="h-100" style="min-height: 82vh">
            <div v-if="isError">
                <center class="mt-3">
                    <h3>Something went wrong...</h3>
                    <p>{{ isError }}</p>
                </center>
            </div>
            <div v-else-if="res.status == 'unauthorized'">
                <center>
                    <h4>You don't have access buddy</h4>
                    <button class="btn">Request Access</button>
                </center>
            </div>

            <div
                v-else-if="res.status == 'success'"
                class="h-100"
                style="min-height: 82vh"
            >
                <a
                    v-if="res.type != 'pdfres'"
                    :download="Date.now() + '.' + res.type"
                    :href="res.base64"
                    class="btn btn-success mb-2 float-right"
                    target="_blank"
                    ><feather-icon icon="DownloadIcon" class="mr-1" size="12" />
                    Download</a
                >
                <embed
                    v-if="res.type != 'docx' && res.type != 'doc'"
                    :src="res.type == 'pdf' ? res.resource : res.base64"
                    frameBorder="0"
                    scrolling="auto"
                    height="100%"
                    width="100%"
                    style="min-height: 82vh"
                />
                <iframe
                    v-else
                    :src="
                        'https://view.officeapps.live.com/op/embed.aspx?src=' +
                        res.resource +
                        '&embedded=true'
                    "
                    frameBorder="0"
                    scrolling="auto"
                    height="100%"
                    width="100%"
                    style="min-height: 82vh"
                ></iframe>
            </div>

            <div v-else>
                {{ res.status }}
            </div>
        </div>
    </div>
</template>

<script>
import axios from "@axios";
import Vue from "vue";
import VueCookies from "vue-cookies";
Vue.use(VueCookies);

export default {
    data() {
        return {
            res: null,
            isError: null,
            isLoading: true,
        };
    },
    created() {
        this.checkUser();
    },
    mounted() {
        axios
            .post("/serve", { id: this.$route.query.id })
            .then((result) => {
                this.res = result.data;
            })
            .catch((err) => {
                this.isError = err;
                console.log(err);
            })

            .finally(() => (this.isLoading = false));
    },
    methods: {
        checkUser() {
            if (!this.$cookies.get("userData")) {
                // localStorage.removeItem("userData");
                this.$router.push("/login");
            }
        },
    },
};
</script>
